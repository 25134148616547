import React, { useState, useEffect,useContext } from 'react'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/light.css'
import classNames from 'classnames'
import {Dutch} from 'flatpickr/dist/l10n/nl.js'
import { DateTime } from 'luxon'

import Error from 'src/components/forms/Error'
import {useVisitorApi} from "../../hooks/useApi";
import {Context as BookingContext} from "../../context/BookingContext";
import {usePageContext} from "../../context/PageContext";
const INPUT = classNames(
    'border border-solid border-grey-500 rounded mb-2',
    'bg-white overflow-hidden flex items-stretch',
    'appearance-none w-full px-4 py-3 flex-1 outline-none',
    'text-base font-normal',
);

const AFTER = DateTime.utc().plus({ days: 1 }).toFormat("kkkk-LL-dd'T'HH:mm:ssZZ");
const BEFORE = DateTime.utc().endOf('month').toFormat("kkkk-LL-dd'T'HH:mm:ssZZ");

export default function DatePicker({ onChange, error, isInline }) {
    const {getTeacherAvailabilities} = useVisitorApi();
    const {state: {teacherID, teacherAvailability, enableDays, selectedDay}, changeBooking} = useContext(BookingContext);
    const {lang} = usePageContext();

    function setLocale() {
        switch (lang) {
            case 'nl':
                return Dutch
            default:
                return null
        }
    }

    async function fetchAvailabilities(before = BEFORE, after = AFTER) {
        const res = await getTeacherAvailabilities({id: teacherID, after, before});
        const onlyDates = [...new Set(res.map(lesson => DateTime.fromISO(lesson.starts_at, {zone: 'gmt'}).toISODate()))]
        changeBooking({
            teacherAvailability: res,
            enableDays: onlyDates
        })
    }

    useEffect(() => {
        fetchAvailabilities()
    }, [])

    function updateDate(date) {
        let currDay = DateTime.fromJSDate(date[0]).toISODate();
        onChange(currDay, 'selectedDate')

        changeBooking({
            selectedDay: currDay
        })

        updateAvailableTimeslots(currDay)
    }

    function updateAvailableTimeslots(forThisDay) {
        let list = [];

        teacherAvailability.forEach(slot => {
            if (DateTime.fromISO(slot.starts_at, {zone: 'gmt'}).toISODate() === forThisDay) {
                list.push(slot)
            }
        })

        changeBooking({
            currentTimeslotsAvailable: list
        })
    }

    function changeMonth(date, dStr, fp) {
        const after = DateTime.fromObject({
            year: fp.currentYear,
            month: fp.currentMonth + 1
        }).startOf('month').plus({hours:6}).toFormat("kkkk-LL-dd'T'HH:mm:ssZZ");
        const before = DateTime.fromObject({
            year: fp.currentYear,
            month: fp.currentMonth + 1
        }).endOf('month').toFormat("kkkk-LL-dd'T'HH:mm:ssZZ");

        fetchAvailabilities(before, after)
    }
    return (
        <>
            <Flatpickr
                value={selectedDay}
                className={classNames(isInline ? null : INPUT)}
                options={{
                    altInput: true,
                    altFormat: 'j F Y',
                    minDate: 'today',
                    enable: [
                        function (date) {
                            return enableDays ? enableDays.includes(DateTime.fromJSDate(date).toISODate()) : null
                        }
                    ],
                    inline: true,
                    locale: {
                        ...setLocale(),
                        firstDayOfWeek: 1
                    }
                }}
                onChange={updateDate}
                onMonthChange={changeMonth}
            />
            <Error>{error}</Error>
        </>
    )
}
