import React from 'react'
import { Link } from 'gatsby'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import Clock from 'src/components/icons/Clock'
import Calendar from 'src/components/icons/Calendar'
import Badge from 'src/components/atoms/Badge'
import useLocalizedRoutes from "../../../hooks/useLocalizedRoutes";
import Zoom from "../../icons/Zoom";

export default function Lesson({ lesson, onClickReschedule }) {
    const { t, i18n } = useTranslation();
    const { teacherProfile } = useLocalizedRoutes();

    function checkIfRescheduleIsPossible (startsAt, status, rescheduledFrom) {
        if(!rescheduledFrom) {
            if(status !== 'confirmed') {
                return false;
            }
            let today = DateTime.local();
            let {values} = startsAt.diff(today, ['hours']);
            return values.hours > 48
        }

        return false
    }

    function showZoomLink() {
        const currentDate = DateTime.local();
        // starts_at is currently not in the correct timezone
        // `.minus({hours: 1})` should be removed when db times are fixed
        const lessonDate = DateTime.fromISO(lesson.starts_at).minus({hours: 1});
        const { values } = lessonDate.diff(currentDate, ['minutes'])
        return values.minutes <= 30
    }

    function getZoomStartLink() {
        return lesson.booking.meeting ? lesson.booking.meeting.join_url : null
    }

    return (
        <div className="my-6 border border-grey-500 rounded-sm p-4 lg:p-8">
            <div className="flex lg:items-center flex-start">
                <div className="w-auto">
                    <img
                        className="w-16 h-16 lg:w-20 lg:h-20 rounded-full"
                        src={lesson.booking.teacher.profile_picture_url}
                        alt={`${lesson.booking.teacher.first_name} ${lesson.booking.teacher.last_name}`}
                    />
                </div>
                <div className="flex-1 pl-5">
                    <div className="flex items-end flex-wrap">
                        <h2 className="text-2xl lg:text-3xl font-titles font-bold text-dark mr-3">{`${lesson.booking.teacher.first_name} ${lesson.booking.teacher.last_name}`}</h2>
                        {['canceled'].includes(lesson.status.name) && (
                            <Badge
                                className="mb-2"
                                type={lesson.status.name === 'canceled' ? 'error' : 'success'}
                            >{t(`student.profile.lesson.${lesson.status.name}`)}</Badge>
                        )}
                        {lesson.rescheduled_from && (
                            <Badge
                                className="mb-2"
                                type={'success'}
                            >{t(`student.profile.lesson.rescheduled`)}</Badge>
                        )}
                    </div>
                    <div className="flex flex-wrap w-full">
                        <div className="flex-1">
                            <p className="md:inline-block text-grey-800 mr-4">{lesson.booking.instrument.translations[i18n.language]}</p>
                            <Link className="inline-block my-2 lg:my-0 text-dark-blue-600 underline font-semibold" to={`${teacherProfile}/${lesson.booking.teacher.id}`}>{t('student.profile.lesson.teacherDetails')}</Link>
                        </div>
                        {checkIfRescheduleIsPossible(DateTime.fromISO(lesson.starts_at, {zone: 'gmt'}).setLocale('nl'), lesson.status.name, lesson.rescheduled_from) &&
                            <div className="w-auto">
                                <a className={`cursor-pointer font-semibold underline ${lesson.status.name === 'canceled' ? 'text-dark-blue-600' : 'text-grey-600'}`}
                                   onClick={onClickReschedule}>{t('student.profile.lesson.reschedule')}</a>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="flex items-center pt-4">
                <div className="mr-8 inline-flex items-center">
                    <Calendar color={'#82898D'} />
                    <span className="pl-2 text-grey-800">{DateTime.fromISO(lesson.starts_at, {zone: 'gmt'}).setLocale('us').toFormat('LLL dd yyyy')}</span>
                </div>
                <div className="inline-flex items-center">
                    <Clock color={'#82898D'} />
                    <span className="pl-2 text-grey-800">{DateTime.fromISO(lesson.starts_at, {zone: 'gmt'}).setLocale('nl').toFormat('HH:mm')} - {DateTime.fromISO(lesson.ends_at, {zone: 'gmt'}).setLocale('nl').toFormat('HH:mm')}</span>
                </div>
            </div>
            <div className="mt-4">
                {!showZoomLink() &&
                    <p className="font-semibold text-dark-blue-600">{t('teacher.account.upcomingLessons.zoomLinkIn30')}</p>
                }
                {(showZoomLink() && getZoomStartLink()) &&
                <a
                    href={getZoomStartLink()}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="font-semibold bg-zoom py-4 px-6 text-white inline-block"
                >
                    <div className="flex items-center">
                        <Zoom w={24}/>
                        <span className="pl-3">
                            {t('teacher.account.upcomingLessons.zoomActivated', {
                                teacher: `${lesson.booking.teacher.first_name} ${lesson.booking.teacher.last_name}`,
                                start_lesson: '...'
                            })}
                        </span>
                    </div>
                </a>
                }
            </div>
        </div>
    )
}
