import React, {useContext, useEffect, useState} from 'react'
import classNames from 'classnames'
import {DateTime} from 'luxon'
import {Context as BookingContext} from 'src/context/BookingContext'
import {useTranslation} from 'react-i18next'

const ACTIVE = "bg-dark-blue-600 text-white px-2 md:px-4 py-2 rounded  cursor-pointer text-sm text-center";
const DEFAULT = "bg-grey-300 px-2 md:px-4 py-2 rounded cursor-pointer text-sm text-center";

export default function TimeslotPicker({onChange, lessonToSchedule, lessonsScheduled}) {
    const [timeSelected, setTimeSelected] = useState(lessonToSchedule ? lessonToSchedule.selectedTime : null);
    const {state: {currentTimeslotsAvailable, duration}} = useContext(BookingContext);
    const [hourTimeslots, setHourTimeslots] = useState([]);
    const {t} = useTranslation();

    function groupHour() {
        let cache = [];
        let finalList = []

        currentTimeslotsAvailable.forEach(slot => {
            if (!cache.includes(slot)) cache.push(slot)
            let withinHour = cache.find(time => {
                // check if exists a slot that starts 30min earlier
                return DateTime.fromISO(time.starts_at, {zone: 'gmt'}).toUTC().toISO() === (DateTime.fromISO(slot.starts_at, {zone: 'gmt'}).toUTC().minus({minutes: 30}).toISO())
            });
            if (withinHour) {
                finalList.push([withinHour, slot])
            }
        });

        if(lessonsScheduled) {
            lessonsScheduled.map((lessonScheduled) => {
                if(lessonScheduled.availabilityID) {
                    finalList.map((list, index) => {
                        if(list.length) {
                            let array = [];
                            list.map((hour) => {
                                array.push(hour.id)
                            });
                            const isEqual = (array.length === lessonScheduled.availabilityID.length) && (array.every(val => lessonScheduled.availabilityID.includes(val)));
                            if(isEqual) {
                                finalList.splice(index, 1)
                            }
                        }
                    })
                }
            })
        }

        setHourTimeslots(finalList)
    }

    useEffect(() => {
        if (duration === 60) groupHour()
    }, [currentTimeslotsAvailable]);

    function selectActive(time) {
        return time === timeSelected ? ACTIVE : DEFAULT;
    }

    function changeHalfHour(time) {
        onChange(time.starts_at, 'selectedTime')
        onChange([time.id], 'availabilityID')
        setTimeSelected(time)
    }

    function changeHour(slot) {
        onChange(slot[0].starts_at, 'selectedTime')
        onChange([slot[0].id, slot[1].id], 'availabilityID')
        setTimeSelected(slot[0])
    }

    function renderHourTimeSlots() {
        if(duration === 60 && hourTimeslots.length) return (
            <div className="grid grid-cols-4 gap-4">
                {hourTimeslots.map((hour, index) => {
                    return (
                        <span
                            className={classNames(selectActive(hour[0]))}
                            onClick={() => changeHour(hour)}
                            key={index}>
                            {DateTime.fromISO(hour[0].starts_at, {zone: 'gmt'}).toFormat('HH:mm')}
                        </span>
                    )
                })}
            </div>
        )

        else if (duration === 60 && !hourTimeslots.length) return (
            <p>{t('bookATeacher.noHourlySlots')}</p>
        )
    }

    function renderHalfHourTimeslots() {
        const correctedCurrentTimeslotsAvailable = filterSelectedTimeSlots() ?? currentTimeslotsAvailable

        if(duration <= 30 && correctedCurrentTimeslotsAvailable.length) return (
            <div className="grid grid-cols-4 gap-4">
                {correctedCurrentTimeslotsAvailable.map((time, index) => (
                    <span
                        className={classNames(selectActive(time))}
                        onClick={() => changeHalfHour(time)}
                        key={index}>
                    {DateTime.fromISO(time.starts_at, {zone: 'gmt'}).toFormat('HH:mm')}
                </span>
                ))}
            </div>
        )

        else if (duration === 30 && !hourTimeslots.length) return (
            <p>{t('bookATeacher.noHalfHourlySlots')}</p>
        )
    }

    function filterSelectedTimeSlots() {
        let selectedTimes = []
        lessonsScheduled.forEach(lesson => {
            if (lesson.selectedTime) {
                selectedTimes.push(lesson.selectedTime)
            }
        })

        if (selectedTimes.length) {
            return currentTimeslotsAvailable.filter(timeSlot => {
                if (timeSelected && timeSlot.starts_at === timeSelected.starts_at) {
                    return true
                }

                return !selectedTimes.includes(timeSlot.starts_at)
            });
        }
    }

    if (lessonToSchedule) {
        return (
            <>
                {renderHalfHourTimeslots()}
                {renderHourTimeSlots()}
            </>
        )
    }

    return null
}
