import React, { useEffect, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Context as ThemeContext } from 'src/context/ThemeContext'
import { Context as ProfileContext } from 'src/context/ProfileContext'
import {Context as BookingContext} from "../../context/BookingContext";
import usePrivateRoute from 'src/hooks/usePrivateRoute'
import MarketingLayout from 'src/components/layout/MarketingLayout'
import NavBar from 'src/components/sections/student/NavBar'
import StudentProfileHeader from 'src/components/sections/student/Header'
import Lesson from 'src/components/sections/student/Lesson'
import PrimaryButton, {OutlinedButton} from 'src/components/buttons/Primary'
import Modal from 'src/components/modal/Modal'
import DatePicker from 'src/components/forms/DatePicker'
import TimeslotPicker from 'src/components/forms/TimeslotPicker'
import 'src/components/forms/DatePicker.css'
import {useVisitorApi} from "../../hooks/useApi"
import {DateTime} from 'luxon'
import Success from "../../components/forms/Success";
import {Error} from "../../components/forms";

const BEFORE = null;
const AFTER = DateTime.local().toFormat("kkkk-LL-dd'T'HH:mm:ssZZ")

export default function StudentAccount(props) {
    const { t } = useTranslation();
    const [modalActive, setModalActive] = useState(false);
    const [modalData, setModalData] = useState(null);
    const { setTheme } = useContext(ThemeContext);
    const { state: { profile } } = useContext(ProfileContext);
    const {state, changeBooking, lessonsToSchedule} = useContext(BookingContext);
    const [lessonsToDisplay, setLessonsToDisplay] = useState([]);
    const { getTeacherLessons, rescheduleLesson, getMe } = useVisitorApi();
    const [ lesson, setLesson ] = useState({selectedDate: null, selectedTime: null, availabilityID: null});
    const [loading, setLoading] = useState(false);
    const [loadingPagination, setLoadingPagination] = useState(false);
    const [ error, setError] = useState(null);
    const [successMsg, setSuccessMsg] = useState(false);
    const [page, setPage] = useState(1);

    usePrivateRoute();
    useEffect(() => setTheme('inverse'), []);

    function fetchStudentLessons(obj) {
        obj.page = page;
        getTeacherLessons(obj).then((response) => {
            setLoadingPagination(false)
            setLessonsToDisplay(page === 1 ? response.data : lessonsToDisplay.concat(response.data))
        })
    }

    useEffect(() => {
        fetchStudentLessons({before: BEFORE, after: AFTER})
    }, [page]);

    useEffect(function getUser(){
        getMe()
    }, []);

    function openModal(lesson) {
        setModalActive(!modalActive);
        setModalData(lesson);
        const duration = DateTime.fromISO(lesson.ends_at, {zone: 'gmt'}).diff(DateTime.fromISO(lesson.starts_at, {zone: 'gmt'}), ['minutes']).values.minutes;
        changeBooking({
            ...state,
            selectedDay: DateTime.fromISO(state.selectedDay, {zone: 'gmt'}).toISO(),
            teacherID: lesson.booking.teacher.id,
            instrument: lesson.instrument,
            teacher: lesson.booking.teacher,
            duration: duration,
        })
    }

    function updateSchedule(inputValue, key) {
        const tempLesson = lesson;
        tempLesson[key] = inputValue;
        setLesson(tempLesson);
        let updatedLessons = [tempLesson];
        changeBooking({lessonsToSchedule: updatedLessons})
    }

    function loadMore () {
        setLoadingPagination(true)
        setPage(page + 1)
    }

    async function rescheduleBookingStudent() {
        setLoading(true)
        const lessonId = modalData.id;
        const availabilities = lesson.availabilityID;
        rescheduleLesson(lessonId, {availabilities:availabilities}).then(response => {
            if(!response.error) {
                setModalActive(false);
                fetchStudentLessons({before: BEFORE, after: AFTER});
                setSuccessMsg(true)
                setTimeout(() => setSuccessMsg(false), 3000)
            } else {
                setError({reschedule: t('student.profile.lesson.rescheduleError')})
            }
            setLoading(false)
        })

    }

    if (profile) {

        return (
            <MarketingLayout {...props} title={profile.first_name}>
                <div className="px-4">
                    <div className="container mx-auto py-8 lg:py-12 flex items-start flex-wrap">
                        <NavBar
                            user={profile}
                        />
                        <div className="w-full lg:w-8/12">
                            <StudentProfileHeader/>
                            <div className="lg:mt-8">
                                {lessonsToDisplay.length ? lessonsToDisplay.map((lesson, i) => (
                                    <Lesson
                                        lesson={lesson}
                                        key={i}
                                        onClickReschedule={() => openModal(lesson)}
                                    />
                                )) : (
                                    <>
                                        <p className="mb-8 text-dark">{t('student.profile.lesson.noPlanned')}</p>
                                        <PrimaryButton>{t('student.profile.lesson.findTeacher')}</PrimaryButton>
                                    </>
                                )}
                                {lessonsToDisplay.length > 0 &&
                                <div className="flex justify-center mt-4">
                                    <OutlinedButton
                                        onClick={loadMore}
                                        isLoading={loadingPagination}
                                    >
                                        {t('general.loadMore')}
                                    </OutlinedButton>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    {successMsg && <Success className="">{t('student.profile.lesson.rescheduled')}</Success>}
                </div>

                <Modal active={modalActive} onClickClose={() => setModalActive(!modalActive)}
                       className="max-w-screen-lg w-full lg:p-16 overflow-y-scroll">
                    {modalData && (
                        <>
                            <h2 className="mb-8 font-bold text-3xl font-titles">Choose a new date</h2>
                            <div className="flex flex-wrap">
                                <div className="w-full md:w-6/12 lg:w-5/12 py-4">
                                    <h3 className="font-semibold font-titles text-xl mb-6">Select date</h3>
                                    <div className="date-picker inline">
                                        <DatePicker
                                            isInline
                                            onChange={updateSchedule}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="md:border-l border-grey-300 md:pl-8 lg:pl-16 w-full md:w-6/12 lg:w-7/12 py-4">
                                    <h3 className="font-semibold font-titles text-xl mb-6">Select time</h3>
                                    <TimeslotPicker
                                        lessonToSchedule={lesson}
                                        onChange={updateSchedule}
                                    />
                                    { error && <Error>{error.reschedule}</Error>}
                                </div>
                                <PrimaryButton isLoading={loading} onClick={() => rescheduleBookingStudent()}>{'Save'}</PrimaryButton>
                            </div>
                        </>
                    )}
                </Modal>
            </MarketingLayout>
        )
    }

    return null
}
