import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'
import Clock from 'src/components/icons/Clock'

export default function StudentProfileHeader() {
    const { t } = useTranslation();

    return (
        <div className="flex flex-wrap items-end justify-between w-full">
            <h2 className="w-full lg:w-auto mb-2 lg:mb-0 font-titles font-bold text-dark text-2xl lg:text-4xl">{t('student.profile.header.title')}</h2>
            {/*<Link
                className="inline-flex items-center pb-2 text-baby-blue-600"
                to={"/"}
            >
                <Clock />
                <span className="pl-2">{t('student.profile.header.past')}</span>
            </Link>*/}
        </div>
    )
}
